// socket types
export const LOAD_AGENT_SEESION = "LOAD_AGENT_SEESION";
export const LIST_AGENT_SESSION = "LIST_AGENT_SESSION";
export const LOAD_CLIENT_SESSION = "LOAD_CLIENT_SESSION";
export const LIST_CLIENT_SESSION = "LIST_CLIENT_SESSION";
export const RETURN_SESSION = "RETURN_SESSION";
export const CLOSE_SESSION = "CLOSE_SESSION";
export const TAKE_SESSION = "TAKE_SESSION";
export const ESCALATE_SESSION = "ESCALATE_SESSION";
export const ADD_ITEM_LIST_AGENT_SESSION = "ADD_ITEM_LIST_AGENT_SESSION";
export const MOVE_ITEM_ARCHIVE_TO_LIST_SESSION =
  "MOVE_ITEM_ARCHIVE_TO_LIST_SESSION";
// export const LOGIN_STATUS = "LOGIN_STATUS"

// sessions offset
export const CLOSE_SESSION_OFFSET = "CLOSE_SESSION_OFFSET";
export const ACTIVE_INACTIVE_SESSION_OFFSET = "ACTIVE_INACTIVE_SESSION_OFFSET";
export const ACTIVE_SESSION_OFFSET = "ACTIVE_SESSION_OFFSET";
export const INACTIVE_SESSION_OFFSET = "INACTIVE_SESSION_OFFSET";
export const ASSIGN_ESCALATED_SESSION_OFFSET =
  "ASSIGN_ESCALATED_SESSION_OFFSET";
export const ESCALATED_SESSION_OFFSET = "ESCALATED_SESSION_OFFSET";
export const CLIENT_SESSION_OFFSET = "CLIENT_SESSION_OFFSET";

export const SELECT_SESSION = "SELECT_SESSION";
export const MESSAGE_LIMIT_STATUS = "MESSAGE_LIMIT_STATUS";
export const GET_SESSION_MSG = "GET_SESSION_MSG";
export const SESSION_MSG_OFFSET = "SESSION_MSG_OFFSET";
export const LOAD_SESSION_MSG = "LOAD_SESSION_MSG";
export const CLEAR_SELECTED_SESSION = "CLEAR_SELECTED_SESSION";
export const TAKE_SESSION_SUCCESS = "TAKE_SESSION_SUCCESS";
export const LEAVE_SESSION_SUCCESS = "LEAVE_SESSION_SUCCESS";
export const SESSION_OPEN_SUCCESS = "SESSION_OPEN_SUCCESS";
export const SESSION_CLOSED_SUCCESS = "SESSION_CLOSED_SUCCESS";
export const SEND_ACKNOWLEDGE = "SEND_ACKNOWLEDGE";
export const RECEIVE_NEW_MESSAGE = "RECEIVE_NEW_MESSAGE";
export const MESSAGE_STATUS = "MESSAGE_STATUS";
export const SEND_MSG = "SEND_MSG";
export const AGENT_TAKE_SESSION_SUCCESS = "AGENT_TAKE_SESSION_SUCCESS";
export const RESET_SELECT_SESSION = "RESET_SELECT_SESSION";
export const SET_DONE_SESSION = "SET_DONE_SESSION";
export const SESSION_AGENT_FILTER = "SESSION_AGENT_FILTER";
export const SESSION_GROUP_FILTER = "SESSION_GROUP_FILTER";
export const APPLY_FILTERS = "APPLY_FILTERS";
export const SAVE_DRAFT_MESSAGE = "SAVE_DRAFT_MESSAGE";
export const DELETE_DRAFT_MESSAGE = "DELETE_DRAFT_MESSAGE";
export const START_VIDEO_CALL = "START_VIDEO_CALL";
export const END_VIDEO_CALL = "END_VIDEO_CALL";
export const VIDEO_CALL_PARTICIPANTS = "VIDEO_CALL_PARTICIPANTS";

// ADMI ACTIONS
export const LOAD_ESCALATED_SEESION = "LOAD_ESCALATED_SEESION";
export const WATCH_SESSION = "WATCH_SESSION";
export const WATCH_SESSION_SUCCESS = "WATCH_SESSION_SUCCESS";
export const LIST_ESCALATED_SEESION = "LIST_ESCALATED_SEESION";
export const RETURN_TO_AGENT = "RETURN_TO_AGENT";
export const TAKE_ESCALATED_SESSION = "TAKE_ESCALATED_SESSION";

// REST types
export const SEND_MSG_API = "SEND_MSG_API";
export const SEND_UPDATE_PENDING = "SEND_UPDATE_PENDING";
export const SET_CLIENT_INFO = "SET_CLIENT_INFO";
export const TRANSFER_GROUP = "TRANSFER_GROUP";
export const GET_CLIENT_INFO = "GET_CLIENT_INFO";

export const UPDATE_VALUE_ENABLE_SEARCH = "UPDATE_VALUE_ENABLE_SEARCH";
