import { Direction, PaletteMode } from "@mui/material";
import linearImg from "../assets/img/Login-background.png";
import InventoLogo from "../assets/img/logo.png";
import patternImage from "../assets/img/pattern.png";
// import InventoLogo from '../assets/logo.png'

//WhatsApp
import WhatsAppBackground from "../assets/general-icon/whatsapp-background.jpg";

const primMain = process.env.REACT_APP_ENV === "cppa" ? "#283034" : "#041656";
const primLight = process.env.REACT_APP_ENV === "cppa" ? "#283034" : "#000747";
const primDark = "#F1F1F1";
const primMainOpacity = "#04165633";

const secMain = "#ffffff";
const secLight = "#F1F1F1";
const secDark = "#000747";

const errorMain = "#EB5757";
const errorDark = "#EB5757";

const successMain = "#4caf50";
const sucessDark = "#4caf50";

const warningMain = "#E75F17";
const warningDark = "#E75F17";

const textPrimary = "#828282";
const textSecondary = "#3497F9";
const textPurple = "#00376B";

const navbarSecondaryColor =
  process.env.REACT_APP_ENV === "cppa" ? "#BE965C" : "#545ea6";
const commonPurple = "#7884F2";
const commonWhite = "#fff";
const commonGrayLight = "#eaeaea";
const commonGray = "#D9D9D9";
const lighterGray = "#D8D8D8";
const comminLinear: string = linearImg;
const textPrimaryDark = "#606061";
const textGrayLight = "#A3A3A3";
const lightWhite = "#e8e6e6";
const offWhite = "#f7f7f8eb";
const darkOffWhite = "#f6f6f6";
const textSecondaryDark = "#416FAB";
const boxShadowColor = "#0000001c";
const boxShadowDark = "#00000075";
const black = "#000";
const successDarker = "#27AE60";
const logoTop: string = InventoLogo;
const logoBottom: string = InventoLogo;
const pattern: string = patternImage;
const butterflyBlue = "#2D9AFF";
const ultramarineBlue = "#405DF9";
const starDust = "#9C9C9C";
const cornflowerBlue = "#5297FF";
const lightNavyBlue = "#385682";
const tropicalBlue = "#C7DDFF";
const paleGrey = "#FDFDFD";
const pastelGrey = "#CDCDCD";
const mirage = "#171C26";
const mistBlue = "#687182";
const riverBed = "#464F60";
const alabaster = "#F9FAFC";
const heather = "#BCC2CE";
const gulfBlue = "#041654";
const dawnPink = "#ECECEE";
const limeGreen = "#28A820";
const blackCow = "#454545";
const whiteSmoke = "#F5F5F5";
const darkWhiteSmoke = "#F9F9F9";
const davyGrey = "#545454";
const liver = "#4F4F4F";
const catalinaBlue = "#003178";
const congressBlue = "#003F86";
const cloud = "#C5C5C5";
const battleshipGrey = "#818181";
const titanWhite = "#E5F0FA";
const aquaHaze = "#F3F3F3";
const hairStyle = "#283034";
const blackOpacity65 = "#000000a6";
const blackOpacity85 = "#000000d9";
const blackOpacity45 = "#00000073";
const blackOpacity25 = "#00000040";
const blackOpacity1 = "#0000001a";
const blackOpacity3 = "#0000003b";
const lightGrey = "#D9D9D9";
const iron = "#D5D8DF";
const darkPastelGreen = "#4CAF50";
const darkPastelGreenOpacity18 = "#4caf502e";
const orangePeel = "#FFA200";
const orangePeelOpacity18 = "#ffa2002e";
const softBlue = "#7884F2";
const softBlueOpacity18 = "#7884f22e";
const amour = "#FCEAEA";
const bilobaFlower = "#A5A6F6";
const cloudOpacity4 = "#c5c5c52e";
const blackOpacity38 = "#00000061";
const greyCloud = "#B5B5B5";
const lavenderPurple = "#9771B7";
const mountainMist = "#93989E";
const crystalBlue = "#6BB8FF";

const warning = "#e8d951";
const scopeUser = "#A000A3";

//Priorty
const low = "#4CAF50";
const medium = "#FAAD14";
const high = "#EB5757";
const urgent = "#AB1717";

// WhatsApp setting
const background: string = WhatsAppBackground;
const recieverColor: string = "#FAFAFA";
const senderColor: string = "#DCF7C5";

//Twitter setting
const commentIcon: string = "#56ccf22f";
const likeIcon: string = "#d6999945";
const retweetIcon: string = "#a3dcac59";

// facebook setting
const recieverFacebook: string = "#F6F5FB";
const senderFacebook: string = "#3E00A8";

// instagram setting
const recieverInstagram: string = "#F6F5FB";
const senderInstagram: string = "#C5003E";
const sending: string = "#c5003e40";

// LinkedIn
const postButton: string = "#0a66c2";

// Video Call speaker indicator
const darkBlue = "#0628a1c1";
const lightBlue = "#0628a170";

// box shadow
const boxShadowWOW: string = `0px 184.989px 147.992px rgba(0, 0, 0, 0.04),
                              0px 55.7689px 44.6151px rgba(0, 0, 0, 0.0294434), 
                              0px 23.1635px 18.5308px rgba(0, 0, 0, 0.0263823), 
                              0px 8.3778px 6.70224px rgba(0, 0, 0, 0.0193761)`;
const boxShadowLAST: string = "6px 5px 23px 0px rgba(205, 201, 201, 0.12);";
const boxShadowSettings: string = "0px 1px 2px rgba(0, 0, 0, 0.25)";

const boxShadowSimple =
  "0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)";

//Opacity
const congressBlueOpacity = "rgba(0, 63, 134,0.06 )";
const errorMainOpacity = "rgba(235, 87, 87,0.32 )";
const darkPrimaryOpacity = "rgba(4, 22, 86, 0.7)"

// Flow nodes
const timeconditionColor = "#5D82AD";
const endflowNode = "#B82012";
const waitNode = "#8EB3DE";
const waitresNode = "#355A85";
const jumpNode = "#ED643B";
const transferNode = "#EEBA00";
const replyNode = "#00D0D2";
const whatsappTrigger = "#24d366";
const borderOpacity = "#003f860f";
const expiredNode = "#ff756c";
const httprequestNode = "#282868";
const webHookNode = "#131349";
const mapNode = "#595999";
const filterNode = "#7A7ABA";
const entityNode = "#6C6CDD";
const imageAnalysisNode = "#4949DA";
const detectLanguageNode = "#2160A7";
const sentimentNode = "#5594DB";
const sortNode = "#3B3BCC";
const formatNode = "#1313A4";
const bgColorFlow = "#ccc";
const selectedNodeBoxShadow = "1px -1px 6px 34px rgba(4, 22, 86, 0.2)";
const warningNodeBoxShadow = "1px -1px 6px 34px rgb(182 183 20 / 40%)";
const errorNodeBoxShadow = "1px -1px 6px 34px rgb(255 0 26 / 20%)";
const nodeBoxShadow = "0px 4px 4px rgba(4, 22, 86, 0.16)";
const inputBoxShadow = "0px 0px 0px 3px rgba(4,22,86,0.2)";
const warningShadowAlert =
  "0px 2px 4px rgba(182 183 20 / 40%), 0px 4px 6px rgba(0, 0, 0, 0.1)";
const warningModal = "#dac819";
const gulfBlueOpacity13 = "#0517574d";

const notificationAlertMsg = "#53bdeb";

// snakBar
const successAlert = "#106132";
const successShadowAlert =
  "0px 2px 4px rgba(16, 97, 50, 0.25), 0px 4px 6px rgba(0, 0, 0, 0.1)";
const errorAlert = "#A83018";
const errorShadowAlert =
  "0px 2px 4px rgba(0, 0, 0, 0.06), 0px 4px 6px rgba(168, 48, 24, 0.25)";
const generalAlert = "#041656";
const generalShadowAlert =
  "0px 2px 4px rgba(4, 22, 86, 0.06), 0px 4px 6px rgba(4, 22, 86, 0.1)";

declare module "@mui/material/styles" {
  interface Theme {
    direction: Direction | string;
  }
  interface ThemeOptions {
    direction: Direction | string;
  }

  interface Palette {
    general: {
      purple: string;
      darkPurple: string;
      grayLight: string;
      grayDark: string;
      grayLighter: string;
      linearImg: string;
      white: string;
      darkPrimary: string;
      lightWhite: string;
      offWhite: string;
      darkSecondary: string;
      boxShadow: string;
      darkOffWhite: string;
      textGrayLight: string;
      black: string;
      successDarker: string;
      boxShadowDark: string;
      boxShadowWOW: string;
      boxShadowLAST: string;
      boxShadowSettings: string;
      butterflyBlue: string;
      ultramarineBlue: string;
      starDust: string;
      cornflowerBlue: string;
      lightNavyBlue: string;
      tropicalBlue: string;
      paleGrey: string;
      pastelGrey: string;
      mirage: string;
      mistBlue: string;
      riverBed: string;
      alabaster: string;
      heather: string;
      gulfBlue: string;
      dawnPink: string;
      limeGreen: string;
      blackCow: string;
      whiteSmoke: string;
      darkWhiteSmoke: string;
      davyGrey: string;
      liver: string;
      catalinaBlue: string;
      congressBlue: string;
      cloud: string;
      battleshipGrey: string;
      congressBlueOpacity: string;
      errorMainOpacity: string;
      darkPrimaryOpacity: string;
      titanWhite: string;
      aquaHaze: string;
      blackOpacity65: string;
      blackOpacity85: string;
      blackOpacity45: string;
      blackOpacity25: string;
      blackOpacity1: string;
      blackOpacity3: string;
      lightGrey: string;
      iron: string;
      darkPastelGreen: string;
      darkPastelGreenOpacity18: string;
      orangePeel: string;
      orangePeelOpacity18: string;
      softBlue: string;
      softBlueOpacity18: string;
      amour: string;
      bilobaFlower: string;
      cloudOpacity4: string;
      blackOpacity38: string;
      greyCloud: string;
      lavenderPurple: string;
      mountainMist: string;
      crystalBlue: string;
      warning: string;
      timeconditionColor: string;
      endflowNode: string;
      waitNode: string;
      expiredNode: string;
      httprequestNode: string;
      webHookNode: string;
      mapNode: string;
      filterNode: string;
      entityNode: string;
      imageAnalysisNode: string;
      detectLanguageNode: string;
      sentimentNode: string;
      sortNode: string;
      formatNode: string;
      bgColorFlow: string;
      selectedNodeBoxShadow: string;
      warningNodeBoxShadow: string;
      errorNodeBoxShadow: string;
      nodeBoxShadow: string;
      inputBoxShadow: string;
      warningShadowAlert: string;
      warningModal: string;
      gulfBlueOpacity13: string;
      notificationAlertMsg: string;
      waitresNode: string;
      jumpNode: string;
      transferNode: string;
      replyNode: string;
      whatsappTrigger: string;
      borderOpacity: string;
      scopeUser: string;
      successAlert: string;
      successShadowAlert: string;
      errorAlert: string;
      errorShadowAlert: string;
      generalAlert: string;
      generalShadowAlert: string;
      boxShadowSimple: string;
      hairStyle: string;
      low: string;
      medium: string;
      high: string;
      urgent: string;
    };
    design: {
      topLogo: string;
      bottomLogo: string;
      pattern: string;
    };
    whatsApp: {
      background: string;
      senderColor: string;
      recieverColor: string;
    };
    twitter?: {
      comment: string;
      like: string;
      retweet: string;
    };
    facebook?: {
      reciever: string;
      sender: string;
    };
    instagram?: {
      reciever: string;
      sender: string;
      sending: string;
    };
    linkedIn: {
      postButton?: string;
    };
  }

  interface PaletteOptions {
    general?: {
      primMainOpacity: string;
      purple: string;
      darkPurple: string;
      grayLight: string;
      grayDark: string;
      grayLighter: string;
      linearImg: string;
      white: string;
      darkPrimary: string;
      lightWhite: string;
      offWhite: string;
      darkSecondary: string;
      boxShadow: string;
      darkOffWhite: string;
      textGrayLight: string;
      black: string;
      successDarker: string;
      boxShadowDark: string;
      butterflyBlue: string;
      ultramarineBlue: string;
      starDust: string;
      cornflowerBlue: string;
      lightNavyBlue: string;
      tropicalBlue: string;
      paleGrey: string;
      pastelGrey: string;
      mirage: string;
      mistBlue: string;
      riverBed: string;
      alabaster: string;
      heather: string;
      gulfBlue: string;
      dawnPink: string;
      limeGreen: string;
      blackCow: string;
      whiteSmoke: string;
      darkWhiteSmoke: string;
      davyGrey: string;
      liver: string;
      catalinaBlue: string;
      congressBlue: string;
      cloud: string;
      battleshipGrey: string;
      congressBlueOpacity: string;
      errorMainOpacity: string;
      darkPrimaryOpacity: string;
      titanWhite: string;
      aquaHaze: string;
      blackOpacity65: string;
      blackOpacity85: string;
      blackOpacity45: string;
      blackOpacity25: string;
      blackOpacity1: string;
      blackOpacity3: string;
      lightGrey: string;
      iron: string;
      darkPastelGreen: string;
      darkPastelGreenOpacity18: string;
      orangePeel: string;
      orangePeelOpacity18: string;
      softBlue: string;
      softBlueOpacity18: string;
      amour: string;
      bilobaFlower: string;
      cloudOpacity4: string;
      blackOpacity38: string;
      greyCloud: string;
      lavenderPurple: string;
      mountainMist: string;
      crystalBlue: string;
      warning: string;
      timeconditionColor: string;
      endflowNode: string;
      waitNode: string;
      expiredNode: string;
      httprequestNode: string;
      webHookNode: string;
      mapNode: string;
      filterNode: string;
      entityNode: string;
      imageAnalysisNode: string;
      detectLanguageNode: string;
      sentimentNode: string;
      sortNode: string;
      formatNode: string;
      bgColorFlow: string;
      selectedNodeBoxShadow: string;
      warningNodeBoxShadow: string;
      errorNodeBoxShadow: string;
      nodeBoxShadow: string;
      inputBoxShadow: string;
      warningShadowAlert: string;
      warningModal: string;
      gulfBlueOpacity13: string;
      notificationAlertMsg: string;
      waitresNode: string;
      jumpNode: string;
      transferNode: string;
      replyNode: string;
      whatsappTrigger: string;
      borderOpacity: string;
      successAlert: string;
      successShadowAlert: string;
      errorAlert: string;
      errorShadowAlert: string;
      generalAlert: string;
      generalShadowAlert: string;
      boxShadowSimple: string;
      hairStyle: string;
      low: string;
      medium: string;
      high: string;
      urgent: string;
      darkBlue: string;
      lightBlue: string;
    };
    design?: {
      topLogo: string;
      bottomLogo: string;
      pattern: string;
    };
    whatsApp?: {
      background: string;
      senderColor: string;
      recieverColor: string;
    };
    twitter?: {
      comment: string;
      like: string;
      retweet: string;
    };
    facebook?: {
      reciever: string;
      sender: string;
    };
    instagram?: {
      reciever: string;
      sender: string;
      sending: string;
    };
    linkedIn?: {
      postButton: string;
    };
  }
}

export const themeConfig = (mode: PaletteMode, direction: Direction) => {
  return {
    direction: direction,
    palette: {
      mode: mode,
      primary: {
        main: mode === "light" ? primMain : primDark,
        light: primLight,
      },
      secondary: {
        main: mode === "light" ? secMain : secDark,
        light: secLight,
      },
      error: {
        main: mode === "light" ? errorMain : errorDark,
      },
      success: {
        main: mode === "light" ? successMain : sucessDark,
      },
      warning: {
        main: mode === "light" ? warningMain : warningDark,
      },
      text: {
        primary: textPrimary,
        secondary: textSecondary,
      },
      general: {
        primMainOpacity: primMainOpacity,
        purple: commonPurple,
        darkPurple: textPurple,
        grayLight: commonGrayLight,
        grayDark: commonGray,
        grayLighter: lighterGray,
        linearImg: comminLinear,
        white: commonWhite,
        darkPrimary: textPrimaryDark,
        lightWhite,
        offWhite: offWhite,
        darkSecondary: textSecondaryDark,
        boxShadow: boxShadowColor,
        boxShadowWOW: boxShadowWOW,
        boxShadowLAST: boxShadowLAST,
        boxShadowSettings: boxShadowSettings,
        darkOffWhite: darkOffWhite,
        iconSubColor: navbarSecondaryColor,
        black: black,
        textGrayLight,
        successDarker,
        boxShadowDark,
        butterflyBlue,
        ultramarineBlue,
        starDust,
        cornflowerBlue,
        lightNavyBlue,
        tropicalBlue,
        paleGrey,
        pastelGrey,
        mirage,
        mistBlue,
        riverBed,
        alabaster,
        heather,
        gulfBlue,
        dawnPink,
        limeGreen,
        blackCow,
        whiteSmoke,
        darkWhiteSmoke,
        davyGrey,
        liver,
        catalinaBlue,
        congressBlue,
        cloud,
        battleshipGrey,
        congressBlueOpacity,
        errorMainOpacity,
        darkPrimaryOpacity,
        titanWhite,
        aquaHaze,
        blackOpacity65,
        blackOpacity85,
        blackOpacity45,
        blackOpacity25,
        blackOpacity1,
        blackOpacity3,
        lightGrey,
        iron,
        darkPastelGreen,
        darkPastelGreenOpacity18,
        orangePeel,
        orangePeelOpacity18,
        softBlue,
        softBlueOpacity18,
        amour,
        bilobaFlower,
        cloudOpacity4,
        blackOpacity38,
        greyCloud,
        lavenderPurple,
        mountainMist,
        crystalBlue,
        warning,
        timeconditionColor,
        endflowNode,
        waitNode,
        expiredNode,
        httprequestNode,
        webHookNode,
        mapNode,
        filterNode,
        entityNode,
        imageAnalysisNode,
        detectLanguageNode,
        sentimentNode,
        sortNode,
        formatNode,
        bgColorFlow,
        selectedNodeBoxShadow,
        warningNodeBoxShadow,
        errorNodeBoxShadow,
        nodeBoxShadow,
        inputBoxShadow,
        waitresNode,
        jumpNode,
        transferNode,
        replyNode,
        whatsappTrigger,
        borderOpacity,
        scopeUser,
        successAlert,
        successShadowAlert,
        warningShadowAlert,
        warningModal,
        gulfBlueOpacity13,
        notificationAlertMsg,
        errorAlert,
        errorShadowAlert,
        generalAlert,
        generalShadowAlert,
        boxShadowSimple,
        hairStyle,
        low,
        medium,
        high,
        urgent,
        darkBlue,
        lightBlue,
      },
      design: {
        topLogo: logoTop,
        bottomLogo: logoBottom,
        pattern: pattern,
      },
      whatsApp: {
        background: background,
        senderColor: senderColor,
        recieverColor: recieverColor,
      },
      twitter: {
        comment: commentIcon,
        like: likeIcon,
        retweet: retweetIcon,
      },
      facebook: {
        reciever: recieverFacebook,
        sender: senderFacebook,
      },
      instagram: {
        reciever: recieverInstagram,
        sender: senderInstagram,
        sending: sending,
      },
      linkedIn: {
        postButton,
      },
    },
    typography: {
      fontFamily: ["IBM Plex Sans Arabic"].join(","),
      lineHeight: "1.6em !important",
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 768,
        md: 1024,
        lg: 1190,
        xl: 1880,
      },
    },
  };
};
