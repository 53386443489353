export enum SessionType {
  ESCALATED = "ESCALATED",
  ASSIGNED_ESCALATED = "ASSIGNED_ESCALATED",
  ASSIGNED = "ASSIGNED",
  UNASSIGNED = "UNASSIGNED",
  CLOSED = "CLOSED",
}

export enum SessionStatus {
  Active = "Active",
  Inactive = "Inactive",
  Close = "close",
  Escalated = "Escalated",
  Assigned_escalated = "Assigned Escalated",
}
